<template>
  <div
    class="custom-checkbox"
    :class="{ 'with-dropdown': items.length > 0, active: isActive }"
  >
    <div class="">
      <label>
        <input
          type="checkbox"
          :checked="checked"
          :disabled="false"
          @click="
            (e) => {
              checkboxClick(id, title);
            }
          "
        />
        <span />
        <img
          src="@/assets/images/svg/arrow-down.svg"
          alt=""
          v-if="items.length > 0"
          @click.prevent.stop="
            () => {
              isActive = !isActive;
            }
          "
        />
        {{ title }}
      </label>
    </div>
    <article v-if="items.length > 0">
      <label class="custom-checkbox__search">
        <input type="text" autofocus v-model="searchText" />
        <img src="@/assets/images/svg/search.svg" alt="" />
      </label>
      <div v-for="item in items" :key="item.id">
        <label
          v-if="
            searchText === '' ||
            item.name.toLowerCase().includes(searchText.toLowerCase())
          "
        >
          <input
            type="checkbox"
            :checked="item_ids.includes(item.id)"
            :disabled="false"
            @click="
              (e) => {
                subCheckboxClick(item.id, item.name);
              }
            "
          />
          <span />
          {{ item.name }}
        </label>
      </div>
      <!-- <CustomCheckbox
        v-for="item in items"
        :key="item.id"
        :title="item.name"
        @click="
          (e) => {
            subCheckboxClick(item.id, item.name);
          }
        "
      /> -->
    </article>
  </div>
</template>

<script>
export default {
  name: "CustomCheckbox",
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    checked: Boolean,
    disabled: Boolean,
    items: {
      type: Array,
      default: () => [],
    },
    item_ids: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isActive: false,
      searchText: "",
    };
  },
  methods: {
    checkboxClick(id, name) {
      this.$emit("checkbox-click", id, name);
    },
    subCheckboxClick(id, name) {
      this.$emit("sub-checkbox-click", id, name);
    },
  },
};
</script>
