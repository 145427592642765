var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-checkbox",class:{ 'with-dropdown': _vm.items.length > 0, active: _vm.isActive }},[_c('div',{},[_c('label',[_c('input',{attrs:{"type":"checkbox","disabled":false},domProps:{"checked":_vm.checked},on:{"click":function (e) {
            _vm.checkboxClick(_vm.id, _vm.title);
          }}}),_c('span'),(_vm.items.length > 0)?_c('img',{attrs:{"src":require("@/assets/images/svg/arrow-down.svg"),"alt":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return (function () {
            _vm.isActive = !_vm.isActive;
          }).apply(null, arguments)}}}):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")])]),(_vm.items.length > 0)?_c('article',[_c('label',{staticClass:"custom-checkbox__search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],attrs:{"type":"text","autofocus":""},domProps:{"value":(_vm.searchText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchText=$event.target.value}}}),_c('img',{attrs:{"src":require("@/assets/images/svg/search.svg"),"alt":""}})]),_vm._l((_vm.items),function(item){return _c('div',{key:item.id},[(
          _vm.searchText === '' ||
          item.name.toLowerCase().includes(_vm.searchText.toLowerCase())
        )?_c('label',[_c('input',{attrs:{"type":"checkbox","disabled":false},domProps:{"checked":_vm.item_ids.includes(item.id)},on:{"click":function (e) {
              _vm.subCheckboxClick(item.id, item.name);
            }}}),_c('span'),_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()])})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }